

export const POST_RETURN_PRODUCTS_REQUEST = "POST_RETURN_PRODUCTS_REQUEST"
export const POST_RETURN_PRODUCTS_SUCCESS = "POST_RETURN_PRODUCTS_SUCCESS"
export const POST_RETURN_PRODUCTS_FAIL = "POST_RETURN_PRODUCTS_FAIL"


export const GET_RETURN_PRODUCTS_REQUEST = "GET_RETURN_PRODUCTS_REQUEST"
export const GET_RETURN_PRODUCTS_SUCCESS = "GET_RETURN_PRODUCTS_SUCCESS"
export const GET_RETURN_PRODUCTS_FAIL = "GET_RETURN_PRODUCTS_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"