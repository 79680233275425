export const ALL_PURCHASE_REQUEST = "ALL_PURCHASE_REQUEST"
export const ALL_PURCHASE_SUCCESS = "ALL_PURCHASE_SUCCESS"
export const ALL_PURCHASE_FAIL = "ALL_PURCHASE_FAIL"

export const ALL_PURCHASE_POST_REQUEST = "ALL_PURCHASE_POST_REQUEST"
export const ALL_PURCHASE_POST_SUCCESS = "ALL_PURCHASE_POST_SUCCESS"
export const ALL_PURCHASE_POST_FAIL = "ALL_PURCHASE_POST_FAIL"

export const ALL_PURCHASE_UPDATE_REQUEST = "ALL_PURCHASE_UPDATE_REQUEST"
export const ALL_PURCHASE_UPDATE_SUCCESS = "ALL_PURCHASE_UPDATE_SUCCESS"
export const ALL_PURCHASE_UPDATE_FAIL = "ALL_PURCHASE_UPDATE_FAIL"

export const ALL_PURCHASE_DETAILS_REQUEST = "ALL_PURCHASE_DETAILS_REQUEST"
export const ALL_PURCHASE_DETAILS_SUCCESS = "ALL_PURCHASE_DETAILS_SUCCESS"
export const ALL_PURCHASE_DETAILS_FAIL = "ALL_PURCHASE_DETAILS_FAIL"

export const ALL_PURCHASE_DELETE_REQUEST = "ALL_PURCHASE_DELETE_REQUEST"
export const ALL_PURCHASE_DELETE_SUCCESS = "ALL_PURCHASE_DELETE_SUCCESS"
export const ALL_PURCHASE_DELETE_FAIL = "ALL_PURCHASE_DELETE_FAIL"

export const GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_REQUEST = "GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_REQUEST"
export const GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_SUCCESS = "GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_SUCCESS"
export const GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_FAIL = "GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_FAIL"

export const GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST = "GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST"
export const GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS = "GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS"
export const GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL = "GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL"

export const GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST = "GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST"
export const GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS = "GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS"
export const GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL = "GET_PURCHASE_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL"

export const GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST = "GET_PURCAHSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST"
export const GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS = "GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS"
export const GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL = "GET_PURCHASE_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"