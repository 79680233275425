export const GET_TRANSFER_PRODUCT_REQUEST = "GET_TRANSFER_PRODUCT_REQUEST"
export const GET_TRANSFER_PRODUCT_SUCCESS = "GET_TRANSFER_PRODUCT_SUCCESS"
export const GET_TRANSFER_PRODUCT_FAIL = "GET_TRANSFER_PRODUCT_FAIL"

export const GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_REQUEST = "GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_REQUEST"
export const GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_SUCCESS = "GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_SUCCESS"
export const GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_FAIL = "GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_FAIL"

export const GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST = "GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST"
export const GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS = "GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS"
export const GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL = "GET_TRANSFER_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL"

export const GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST = "GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST"
export const GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS = "GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS"
export const GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL = "GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL"

export const GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST = "GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST"
export const GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS = "GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS"
export const GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL = "GET_TRANSFER_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL"


export const POST_TRANSFER_PRODUCT_REQUEST = "POST_TRANSFER_PRODUCT_REQUEST"
export const POST_TRANSFER_PRODUCT_SUCCESS = "POST_TRANSFER_PRODUCT_SUCCESS"
export const POST_TRANSFER_PRODUCT_FAIL = "POST_TRANSFER_PRODUCT_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"