import { useContext, useEffect, useState } from "react";

import { getSpecificReturnProduct } from "../../../actions/returnProductAction";
import { useParams } from "react-router-dom";

export default function Dates({ selectedPrinter }) {
  const [invoicedata, setInvoicedata] = useState()
  const [loading, setLoading] = useState(false);
  // const { salesId } = useContext(State);
  const params = useParams()

  useEffect(() => {
    // console.log(salesId);

    getspecificDataforInvoice();
  }, []);

  const getspecificDataforInvoice = async () => {
    let result = await getSpecificReturnProduct(params.id);
    setInvoicedata(result?.data);
    setLoading(true);
  };

  return (
    <>
      {loading ? (
        <>

            <>
          <div style={{margin: "20px"}} className="mainDiv">
          <div className="DatesFirstDiv">
            <div className="firstDiv">
              <h5 style={{fontFamily: "Calibri"}}>Customer Name:</h5>
              <p>{invoicedata?.customerName}</p>
            </div>
            <div className="secondDiv">
              <h5 style={{fontFamily: "Calibri"}}>Cell No:</h5>
              <p>{invoicedata?.customerNumber}</p>
            </div>
          </div>
          <div className="DatesFirstDiv">
            <div className="firstDiv">
              <h5 style={{fontFamily: "Calibri"}}>Generated By:</h5>
              <p> {invoicedata?.returnBy}</p>
            </div>
            <div className="secondDiv">
              <h5 style={{fontFamily: "Calibri"}}>FBR Invoicer Number:</h5>
              <p> {invoicedata?.invoiceNumber}</p>
            </div>
          </div>
          <div className="DatesFirstDiv">
            <div className="firstDiv">
              <h5>Invoice date:</h5>
              <p> {new Date(invoicedata?.createdAt).toLocaleDateString(
                      "en-GB"
                    )}</p>
            </div>
          
              <>
                <div className="secondDiv">
                  <h5>Invoicer number:</h5>
                  
                  <p> {invoicedata?.id}</p>
                </div>
              </>
       
          </div>
          
          </div>
              {/* <div className="InvoiceSecondCont">
                <div className="InvoiceThirdCont">
                  <div className="consolidateSaleLaserdivs">
                    <h5>Customer Name:</h5>
                    <p>{invoicedata?.customerName}</p>
                  </div>
                  <div className="consolidateSaleLaserdivs">
                    <h5>Cell No:</h5>
                    <p>{invoicedata?.customerNumber}</p>
                  </div>
                  <div className="consolidateSaleLaserdivs">
                    <h5>Generated By:</h5>
                    <p>{invoicedata?.saleBy}</p>
                  </div>
                </div>
                <div className="InvoiceThirdCont">
                  <div className="consolidateSaleSecondLaserdivs">
                    <h5>Invoicer number:</h5>
                    <p>{invoicedata?.id}</p>
                  </div>
                  <div className="consolidateSaleSecondLaserdivs">
                    <h5>Invoice date:</h5>
                    <p>
                      {new Date(invoicedata?.createdAt).toLocaleDateString(
                        "en-GB"
                      )}
                    </p>
                  </div>

                  {invoicedata?.invoiceNumber ? (
                    <>
                    <div className="consolidateSaleSecondLaserdivs">
                      {
                        JSON.parse(localStorage.getItem("SoftwareWithFBR")) && (<>
                          <h5>FBR Invoicer Number:</h5>
                          <p> {invoicedata?.invoiceNumber}</p>
                        </>)
                      }
                      
                      </div>
                    </>
                    ) : (
                    <></>
                  )}
                </div>
              </div> */}
            {/* </div> */}
              {/* <div className="InvoiceLaserFirstRecordDiv">
                <div className="consolidateLaserQuantity">
                  <h5>Customer Name:</h5>
                  <p>{invoicedata?.customerName}</p>
                </div>
                <div className="consolidateLaserInvoiceNumber">
                  <h5>Invoicer number:</h5>
                  <p>{invoicedata?.id}</p>
                </div>
              </div>
              <div className="InvoiceLaserFirstRecordDiv">
                <div className="consolidateLaserQuantity">
                  <h5>Cell No:</h5>
                  <p>{invoicedata?.customerNumber}</p>
                </div>
                <div className="consolidateSaleLaserInvoiceNumber">
                  <h5>Invoice date:</h5>
                  <p>
                    {new Date(invoicedata?.createdAt).toLocaleDateString(
                      "en-GB"
                    )}
                  </p>
                </div>
              </div>
              <div className="InvoiceLaserFirstRecordDiv">
                <div className="consolidateLaserQuantity">
                  <h5>Generated By:</h5>
                  <p>{invoicedata?.saleBy}</p>
                </div>
                {invoicedata?.invoiceNumber ? (
                  <>
                    <div className="consolidateFRBLaserProductCode">
                      <h5>FBR Invoicer Number:</h5>
                      <p> {invoicedata?.invoiceNumber}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div> */}
            </>
          {/* )} */}
        </>
      ) : (
        <h1></h1>
      )}
    </>
  );
}
