export const TASK_REQUEST = "TASK_REQUEST";
export const TASK_SUCCESS = "TASK_SUCCESS";
export const TASK_FAIL = "TASK_FAIL";

export const POST_TASK_REQUEST = "POST_TASK_REQUEST";
export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS";
export const POST_TASK_FAIL = "POST_TASK_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

