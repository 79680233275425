export const ASSIGN_TASKS_REQUEST = "ASSIGN_TASKS_REQUEST";
export const ASSIGN_TASKS_SUCCESS = "ASSIGN_TASKS_SUCCESS";
export const ASSIGN_TASKS_FAIL = "ASSIGN_TASKS_FAIL";

export const ASSIGN_TASKS_BY_ID_REQUEST = "ASSIGN_TASKS_BY_ID_REQUEST";
export const ASSIGN_TASKS_BY_ID_SUCCESS = "ASSIGN_TASKS_BY_ID_SUCCESS";
export const ASSIGN_TASKS_BY_ID_FAIL = "ASSIGN_TASKS_BY_ID_FAIL";

export const ASSIGN_TASKS_BY_ID_AND_NAME_REQUEST =
  "ASSIGN_TASKS_BY_ID_AND_NAME_REQUEST";
export const ASSIGN_TASKS_BY_ID_AND_NAME_SUCCESS =
  "ASSIGN_TASKS_BY_ID_AND_NAME_SUCCESS";
export const ASSIGN_TASKS_BY_ID_AND_NAME_FAIL =
  "ASSIGN_TASKS_BY_ID_AND_NAME_FAIL";

export const UPDATE_ASSIGN_TASKS_REQUEST = "UPDATE_ASSIGN_TASKS_REQUEST";
export const UPDATE_ASSIGN_TASKS_SUCCESS = "UPDATE_ASSIGN_TASKS_SUCCESS";
export const UPDATE_ASSIGN_TASKS_FAIL = "UPDATE_ASSIGN_TASKS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
