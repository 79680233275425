export const ALL_EXPENSE_REQUEST = "ALL_EXPENSE_REQUEST"
export const ALL_EXPENSE_SUCCESS = "ALL_EXPENSE_SUCCESS"
export const ALL_EXPENSE_FAIL = "ALL_EXPENSE_FAIL"

export const ALL_EXPENSE_POST_REQUEST = "ALL_EXPENSE_POST_REQUEST"
export const ALL_EXPENSE_POST_SUCCESS = "ALL_EXPENSE_POST_SUCCESS"
export const ALL_EXPENSE_POST_FAIL = "ALL_EXPENSE_POST_FAIL"

export const ALL_EXPENSE_UPDATE_REQUEST = "ALL_EXPENSE_UPDATE_REQUEST"
export const ALL_EXPENSE_UPDATE_SUCCESS = "ALL_EXPENSE_UPDATE_SUCCESS"
export const ALL_EXPENSE_UPDATE_FAIL = "ALL_EXPENSE_UPDATE_FAIL"

export const ALL_EXPENSE_DETAILS_REQUEST = "ALL_EXPENSE_DETAILS_REQUEST"
export const ALL_EXPENSE_DETAILS_SUCCESS = "ALL_EXPENSE_DETAILS_SUCCESS"
export const ALL_EXPENSE_DETAILS_FAIL = "ALL_EXPENSE_DETAILS_FAIL"

export const ALL_EXPENSE_DELETE_REQUEST = "ALL_EXPENSE_DELETE_REQUEST"
export const ALL_EXPENSE_DELETE_SUCCESS = "ALL_EXPENSE_DELETE_SUCCESS"
export const ALL_EXPENSE_DELETE_FAIL = "ALL_EXPENSE_DELETE_FAIL"

export const GET_EXPENSE_CONSOLIDATED_FOR_SHOPS_REQUEST = "GET_EXPENSE_CONSOLIDATED_FOR_SHOPS_REQUEST"
export const GET_EXPENSE_CONSOLIDATED_FOR_SHOPS_SUCCESS = "GET_EXPENSE_CONSOLIDATED_FOR_SHOPS_SUCCESS"
export const GET_EXPENSE_CONSOLIDATED_FOR_SHOPS_FAIL = "GET_EXPENSE_CONSOLIDATED_FOR_SHOPS_FAIL"

export const GET_EXPENSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST = "GET_EXPENSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST"
export const GET_EXPENSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS = "GET_EXPENSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS"
export const GET_EXPENSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL = "GET_EXPENSE_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"