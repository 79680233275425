export const ALL_COLOR_REQUEST = "ALL_COLOR_REQUEST"
export const ALL_COLOR_SUCCESS = "ALL_COLOR_SUCCESS"
export const ALL_COLOR_FAIL = "ALL_COLOR_FAIL"

export const ALL_COLOR_POST_REQUEST = "ALL_COLOR_POST_REQUEST"
export const ALL_COLOR_POST_SUCCESS = "ALL_COLOR_POST_SUCCESS"
export const ALL_COLOR_POST_FAIL = "ALL_COLOR_POST_FAIL"

export const ALL_COLOR_UPDATE_REQUEST = "ALL_COLOR_UPDATE_REQUEST"
export const ALL_COLOR_UPDATE_SUCCESS = "ALL_COLOR_UPDATE_SUCCESS"
export const ALL_COLOR_UPDATE_FAIL = "ALL_COLOR_UPDATE_FAIL"

export const ALL_COLOR_DETAILS_REQUEST = "ALL_COLOR_DETAILS_REQUEST"
export const ALL_COLOR_DETAILS_SUCCESS = "ALL_COLOR_DETAILS_SUCCESS"
export const ALL_COLOR_DETAILS_FAIL = "ALL_COLOR_DETAILS_FAIL"

export const ALL_COLOR_DELETE_REQUEST = "ALL_COLOR_DELETE_REQUEST"
export const ALL_COLOR_DELETE_SUCCESS = "ALL_COLOR_DELETE_SUCCESS"
export const ALL_COLOR_DELETE_FAIL = "ALL_COLOR_DELETE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"