export const ALL_EXPENSEPAYMENT_REQUEST = "ALL_EXPENSEPAYMENT_REQUEST"
export const ALL_EXPENSEPAYMENT_SUCCESS = "ALL_EXPENSEPAYMENT_SUCCESS"
export const ALL_EXPENSEPAYMENT_FAIL = "ALL_EXPENSEPAYMENT_FAIL"

export const ALL_EXPENSEPAYMENT_POST_REQUEST = "ALL_EXPENSEPAYMENT_POST_REQUEST"
export const ALL_EXPENSEPAYMENT_POST_SUCCESS = "ALL_EXPENSEPAYMENT_POST_SUCCESS"
export const ALL_EXPENSEPAYMENT_POST_FAIL = "ALL_EXPENSEPAYMENT_POST_FAIL"

export const ALL_EXPENSEPAYMENT_UPDATE_REQUEST = "ALL_EXPENSEPAYMENT_UPDATE_REQUEST"
export const ALL_EXPENSEPAYMENT_UPDATE_SUCCESS = "ALL_EXPENSEPAYMENT_UPDATE_SUCCESS"
export const ALL_EXPENSEPAYMENT_UPDATE_FAIL = "ALL_EXPENSEPAYMENT_UPDATE_FAIL"

export const ALL_EXPENSEPAYMENT_DETAILS_REQUEST = "ALL_EXPENSEPAYMENT_DETAILS_REQUEST"
export const ALL_EXPENSEPAYMENT_DETAILS_SUCCESS = "ALL_EXPENSEPAYMENT_DETAILS_SUCCESS"
export const ALL_EXPENSEPAYMENT_DETAILS_FAIL = "ALL_EXPENSEPAYMENT_DETAILS_FAIL"

export const ALL_EXPENSEPAYMENT_DELETE_REQUEST = "ALL_EXPENSEPAYMENT_DELETE_REQUEST"
export const ALL_EXPENSEPAYMENT_DELETE_SUCCESS = "ALL_EXPENSEPAYMENT_DELETE_SUCCESS"
export const ALL_EXPENSEPAYMENT_DELETE_FAIL = "ALL_EXPENSEPAYMENT_DELETE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"