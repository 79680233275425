export const ALL_PRODUCT_LOCATION_REQUEST = "ALL_PRODUCT_LOCATION_REQUEST"
export const ALL_PRODUCT_LOCATION_SUCCESS = "ALL_PRODUCT_LOCATION_SUCCESS"
export const ALL_PRODUCT_LOCATION_FAIL = "ALL_PRODUCT_LOCATION_FAIL"

export const ALL_PRODUCT_LOCATION_ON_STORAGECODE_REQUEST = "ALL_PRODUCT_LOCATION_REQUEST"
export const ALL_PRODUCT_LOCATION_ON_STORAGECODE_SUCCESS = "ALL_PRODUCT_LOCATION_SUCCESS"
export const ALL_PRODUCT_LOCATION_ON_STORAGECODE_FAIL = "ALL_PRODUCT_LOCATION_FAIL"


export const PRODUCT_LOCATION_ON_SHOPTYPE_REQUEST = "PRODUCT_LOCATION_ON_SHOPTYPE_REQUEST"
export const PRODUCT_LOCATION_ON_SHOPTYPE_SUCCESS = "PRODUCT_LOCATION_ON_SHOPTYPE_SUCCESS"
export const PRODUCT_LOCATION_ON_SHOPTYPE_FAIL = "PRODUCT_LOCATION_ON_SHOPTYPE_FAIL"


export const PRODUCT_LOCATION_ON_GODOWNTYPE_REQUEST = "PRODUCT_LOCATION_ON_GODOWNTYPE_REQUEST"
export const PRODUCT_LOCATION_ON_GODOWNTYPE_SUCCESS = "PRODUCT_LOCATION_ON_GODOWNTYPE_SUCCESS"
export const PRODUCT_LOCATION_ON_GODOWNTYPE_FAIL = "PRODUCT_LOCATION_ON_GODOWNTYPE_FAIL"


export const UPDATE_QUANTITY_ON_AVAILIBILITY_AND_PRODUCT_ID_REQUEST = "UPDATE_QUANTITY_ON_AVAILIBILITY_AND_PRODUCT_ID_REQUEST"
export const UPDATE_QUANTITY_ON_AVAILIBILITY_AND_PRODUCT_ID_SUCCESS = "UPDATE_QUANTITY_ON_AVAILIBILITY_AND_PRODUCT_ID_SUCCESS"
export const UPDATE_QUANTITY_ON_AVAILIBILITY_AND_PRODUCT_ID_FAIL = "UPDATE_QUANTITY_ON_AVAILIBILITY_AND_PRODUCT_ID_FAIL"


export const UPDATE_AND_POST_PRODUCT_IN_LOCATION_REQUEST = "UPDATE_AND_POST_PRODUCT_IN_LOCATION_REQUEST"
export const UPDATE_AND_POST_PRODUCT_IN_LOCATION_SUCCESS = "UPDATE_AND_POST_PRODUCT_IN_LOCATION_SUCCESS"
export const UPDATE_AND_POST_PRODUCT_IN_LOCATION_FAIL = "UPDATE_AND_POST_PRODUCT_IN_LOCATION_FAIL"

export const UPDATE_AND_POST_PRODUCT_IN_LOCATION_PURCHASE_REQUEST = "UPDATE_AND_POST_PRODUCT_IN_LOCATION_PURCHASE_REQUEST"
export const UPDATE_AND_POST_PRODUCT_IN_LOCATION_PURCHASE_SUCCESS = "UPDATE_AND_POST_PRODUCT_IN_LOCATION_PURCHASE_SUCCESS"
export const UPDATE_AND_POST_PRODUCT_IN_LOCATION_PURCHASE_FAIL = "UPDATE_AND_POST_PRODUCT_IN_LOCATION_PURCHASE_FAIL"


export const UPDATE_PRODUCT_IN_LOCATION_SALE_REQUEST = "UPDATE_PRODUCT_IN_LOCATION_SALE_REQUEST"
export const UPDATE__PRODUCT_IN_LOCATION_SALE_SUCCESS = "UPDATE_PRODUCT_IN_LOCATION_SALE_SUCCESS"
export const UPDATE_PRODUCT_IN_LOCATION_SALE_FAIL = "UPDATE_PRODUCT_IN_LOCATION_SALE_FAIL"

export const UPDATE_AND_POST_QUANTITY_USING_TRANSFER_REQUEST = "UPDATE_AND_POST_QUANTITY_USING_TRANSFER_REQUEST"
export const UPDATE_AND_POST_QUANTITY_USING_TRANSFER_SUCCESS = "UPDATE_AND_POST_QUANTITY_USING_TRANSFER_SUCCESS"
export const UPDATE_AND_POST_QUANTITY_USING_TRANSFER_FAIL = "UPDATE_AND_POST_QUANTITY_USING_TRANSFER_FAIL"

export const UPDATE_MINUS_QUANTITY_USING_TRANSFER_REQUEST = "UPDATE_MINUS_QUANTITY_USING_TRANSFER_REQUEST"
export const UPDATE_MINUS_QUANTITY_USING_TRANSFER_SUCCESS = "UPDATE_MINUS_QUANTITY_USING_TRANSFER_SUCCESS"
export const UPDATE_MINUS_QUANTITY_USING_TRANSFER_FAIL = "UPDATE_MINUS_QUANTITY_USING_TRANSFER_FAIL"



export const PRODUCT_LOCATION_ON_ID_REQUEST = "PRODUCT_LOCATION_ON_ID_REQUEST"
export const PRODUCT_LOCATION_ON_ID_SUCCESS = "PRODUCT_LOCATION_ON_ID_SUCCESS"
export const PRODUCT_LOCATION_ON_ID_FAIL = "PRODUCT_LOCATION_ON_ID_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"