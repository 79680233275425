import { useState } from "react";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { COMPANYHEADER } from "../../../constants/companyNameContants";
import { getSpecificReturnProduct } from "../../../actions/returnProductAction";
export default function Header({ selectedPrinter }) {
  const [storageAddress, setStorageAddress] = useState("");
  const [storagePhoneNo, setStoragePhoneNo] = useState("");
  const params = useParams()
  useEffect(()=>{
    getspecificDataforInvoice()
  }, [])

  const getspecificDataforInvoice = async () => {
    let result = await getSpecificReturnProduct(params.id);
    // setProductss(result?.products);
    setStorageAddress(result?.data?.address)
    setStoragePhoneNo(result?.data?.phoneNo)
    console.log(result)
    console.log(result?.data?.phoneNo)
    // setLoading(true);
  };
  return (
    <>
      {/* {selectedPrinter === "Laser" ? ( */}
        <div className="headerHeading">
          <h1 className="invoiceTitle">{COMPANYHEADER}</h1>
          <p className="invoiceAddress"> {storageAddress}</p>
          <p className="invoicePhoneNo">
            <p>
              Phone No:
              {storagePhoneNo}
            </p>
          </p>
        </div>
      {/* ) : (
        <div className="headerHeading">
          <h1 className="invoiceTitle">Qureshi Electronics</h1>
          <p className="invoicePhoneNo"> {storageAddress}</p>
          <p className="invoicePhoneNo">
            <p>
              Phone No:
              {storagePhoneNo}
            </p>
          </p>
        </div>
      )} */}
    </>
  );
}
