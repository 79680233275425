export const ALL_TEMP_PURCHASE_REQUEST = "ALL_TEMP_PURCHASE_REQUEST"
export const ALL_TEMP_PURCHASE_SUCCESS = "ALL_TEMP_PURCHASE_SUCCESS"
export const ALL_TEMP_PURCHASE_FAIL = "ALL_TEMP_PURCHASE_FAIL"

export const TEMP_PURCHASE_ON_SHOP_REQUEST = "TEMP_PURCHASE_ON_SHOP_REQUEST"
export const TEMP_PURCHASE_ON_SHOP_SUCCESS = "TEMP_PURCHASE_ON_SHOP_SUCCESS"
export const TEMP_PURCHASE_ON_SHOP_FAIL = "TEMP_PURCHASE_ON_SHOP_FAIL"


export const TEMP_PURCHASE_DETAILS_REQUEST = "TEMP_PURCHASE_DETAILS_REQUEST"
export const TEMP_PURCHASE_DETAILS_SUCCESS = "TEMP_PURCHASE_DETAILS_SUCCESS"
export const TEMP_PURCHASE_DETAILS_FAIL = "TEMP_PURCHASE_DETAILS_FAIL"

export const POST_TEMP_PURCHASE_REQUEST = "POST_TEMP_PURCHASE_REQUEST"
export const POST_TEMP_PURCHASE_SUCCESS = "POST_TEMP_PURCHASE_SUCCESS"
export const POST_TEMP_PURCHASE_FAIL = "POST_TEMP_PURCHASE_FAIL"

export const ALL_TEMP_PURCHASE_DELETE_REQUEST = "ALL_TEMP_PURCHASE_DELETE_REQUEST"
export const ALL_TEMP_PURCHASE_DELETE_SUCCESS = "ALL_TEMP_PURCHASE_DELETE_SUCCESS"
export const ALL_TEMP_PURCHASE_DELETE_FAIL = "ALL_TEMP_PURCHASE_DELETE_FAIL"

export const TEMP_PURCHASE_ITEM_DELETE_REQUEST = "TEMP_PURCHASE_ITEM_DELETE_REQUEST"
export const TEMP_PURCHASE_ITEM_DELETE_SUCCESS = "TEMP_PURCHASE_ITEM_DELETE_SUCCESS"
export const TEMP_PURCHASE_ITEM_DELETE_FAIL = "TEMP_PURCHASE_ITEM_DELETE_FAIL"

export const UPDATE_TEMP_PURCHASE_PRODUCTS_REQUEST = "UPDATE_TEMP_PURCHASE_PRODUCTS_REQUEST"
export const UPDATE_TEMP_PURCHASE_PRODUCTS_SUCCESS = "UPDATE_TEMP_PURCHASE_PRODUCTS_SUCCESS"
export const UPDATE_TEMP_PURCHASE_PRODUCTS_FAIL = "UPDATE_TEMP_PURCHASE_PRODUCTS_FAIL"



export const CLEAR_ERRORS = "CLEAR_ERRORS"