export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST"
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS"
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL"


export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST"
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS"
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL"

export const PRODUCTS_ON_COMPANY_NAME_REQUEST = "PRODUCTS_ON_COMPANY_NAME_REQUEST"
export const PRODUCTS_ON_COMPANY_NAME_SUCCESS = "PRODUCTS_ON_COMPANY_NAME_SUCCESS"
export const PRODUCTS_ON_COMPANY_NAME_FAIL = "PRODUCTS_ON_COMPANY_NAME_FAIL"

export const PRODUCTS_ON_BARCODE_REQUEST = "PRODUCTS_ON_BARCODE_REQUEST"
export const PRODUCTS_ON_BARCODE_SUCCESS = "PRODUCTS_ON_BARCODE_SUCCESS"
export const PRODUCTS_ON_BARCODE_FAIL = "PRODUCTS_ON_BARCODE_FAIL"

export const UPDATE_PURCHASE_PRODUCT_PRICE_REQUEST = "UPDATE_PURCHASE_PRODUCT_PRICE_REQUEST"
export const UPDATE_PURCHASE_PRODUCT_PRICE_SUCCESS = "UPDATE_PURCHASE_PRODUCT_PRICE_SUCCESS"
export const UPDATE_PURCHASE_PRODUCT_PRICE_FAIL = "UPDATE_PURCHASE_PRODUCT_PRICE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"