import React, { useContext, useEffect, useState } from "react";
// import { State } from "../context/ContextSales";
// import QRCode from "react-qr-code";
// import logo from "./pos.png";
import PrintLaserTable from "../../../Components/tableComponent/printLaserTable"
import { getSpecificReturnProduct } from "../../../actions/returnProductAction";
import { useParams } from "react-router-dom";

let quantity = [];
let discount = [];
let totalAmount = [];
let taxAmount = [];
let amount = [];
let excludeTaxPrice = []
export default function Tablee({ selectedPrinter }) {
  const params = useParams()
  let action4 = "salesRecipt";
  let productsforlisting = [];
  const [invoicedata, setInvoicedata] = useState([]);
  const [productss, setProductss] = useState([""]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getspecificDataforInvoice();
  }, []);
  
  const getspecificDataforInvoice = async () => {
    let result = await getSpecificReturnProduct(params.id);
    console.log(result)
    setInvoicedata(result?.data);
    productsforlisting = result?.data?.products;

    quantity = productsforlisting?.reduce((sum, product) => sum + parseInt(product.returnQuantity, 10), 0)
      .toString();
    discount = productsforlisting?.reduce((sum, product) => sum + parseFloat(product.Discount), 0)
      .toString();
    totalAmount = productsforlisting?.reduce((sum, product) => sum + parseFloat(product.totalAmounnt), 0)
      .toString();
    taxAmount = productsforlisting?.reduce((sum, product) => sum + parseFloat(product.taxAmount), 0)
      ?.toString();
    excludeTaxPrice = productsforlisting?.reduce((sum, product) => sum + parseFloat(product.excludeTaxPrice), 0)
    ?.toString();
    amount = productsforlisting?.reduce((sum, product) => sum + parseFloat(product.amount), 0)
      .toString();
    taxAmount = Number(taxAmount);
    taxAmount = taxAmount.toFixed(2);
    setProductss(result?.data?.products);
    setLoading(true);
  };

  const columns = [
    { field: "Code", label: "Code" },
    { field: "name", label: "Name" },
    { field: "color", label: "Color" },
    { field: "Company", label: "Company" },
    { field: "returnQuantity", label: "Quantity" },
    { field: "excludeTaxPrice", label: "MRP" },
    { field: "totalAmounnt", label: "Total" },
    { field: "Discount", label: "Discount" },
    { field: "taxAmount", label: "Tax" },
    { field: "amount", label: "Due Amount" },
  ];

  const columns1 = [
    { field: "Code", label: "Code" },
    { field: "name", label: "Name" },
    { field: "color", label: "Color" },
    { field: "Company", label: "Company" },
    { field: "returnQuantity", label: "Quantity" },
    { field: "excludeTaxPrice", label: "MRP" },
    { field: "totalAmounnt", label: "Total" },
    { field: "Discount", label: "Discount" },
    { field: "amount", label: "Due Amount" },
  ];
  const column2 = [
    { field: "PurchaseQuantity", label: "Qty" },
    { field: "purchaseTotalDiscount", label: "Discount" },
    { field: "purchaseQuantityPrice", label: "Price" },
    { field: "purchaseTotalTax", label: "Tax" },
    { field: "purchaseTotalAmount", label: "Due Amount" },
  ];
  return (
    <>
      {loading ? (
        <>
          <div className="printTable">
            {
              JSON.parse(localStorage.getItem("SoftwareWithFBR")) ? (
          
              <PrintLaserTable
                data={productss}
                columns={columns}
                action4={action4}
                ConsolidatedInvoiceTotalquantity={quantity}
                ConsolidatedInvoiceTotaldiscount={discount}
                ConsolidatedInvoiceTotaltotalPriceExculdingTax={totalAmount}
                ConsolidatedInvoiceTotaltotalTaxAmount={taxAmount}
                ConsolidatedInvoiceTotalIncludingAllPrices={amount}
                consolidateInvoiceExcludeTaxPrice = {excludeTaxPrice}
              /> 
              ) :(<>
              <PrintLaserTable
                data={productss}
                columns={columns1}
                action4={action4}
                ConsolidatedInvoiceTotalquantity={quantity}
                ConsolidatedInvoiceTotaldiscount={discount}
                ConsolidatedInvoiceTotaltotalPriceExculdingTax={totalAmount}
                ConsolidatedInvoiceTotaltotalTaxAmount={taxAmount}
                ConsolidatedInvoiceTotalIncludingAllPrices={amount}
                consolidateInvoiceExcludeTaxPrice = {excludeTaxPrice}
              /> 
              </>)
            }
              
            {/* )} */}
          </div>
          <div className="bottomGrand">
          <h2>
            Grand Total. {invoicedata?.total}
          </h2>
      </div>  
    
         
        </>
      ) : (
        <h1></h1>
      )}

    
    </>
  );
}
