
export const GET_PAID_COMMISSION_CONSOLIDATED_FOR_SHOPS_REQUEST = "GET_PAID_COMMISSION_CONSOLIDATED_FOR_SHOPS_REQUEST"
export const GET_PAID_COMMISSION_CONSOLIDATED_FOR_SHOPS_SUCCESS = "GET_PAID_COMMISSION_CONSOLIDATED_FOR_SHOPS_SUCCESS"
export const GET_PAID_COMMISSION_CONSOLIDATED_FOR_SHOPS_FAIL = "GET_PAID_COMMISSION_CONSOLIDATED_FOR_SHOPS_FAIL"

export const GET_COMMISSION_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST = "GET_COMMISSION_CONSOLIDATED_FOR_SHOPS_ON_DATE_REQUEST"
export const GET_COMMISSION_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS = "GET_COMMISSION_CONSOLIDATED_FOR_SHOPS_ON_DATE_SUCCESS"
export const GET_COMMISSION_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL = "GET_COMMISSION_CONSOLIDATED_FOR_SHOPS_ON_DATE_FAIL"

export const GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST = "GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_REQUEST"
export const GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS = "GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_SUCCESS"
export const GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL = "GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_FAIL"

export const GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST = "GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_REQUEST"
export const GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS = "GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_SUCCESS"
export const GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL = "GET_COMMISSION_CONSOLIDATED_FOR_SPECIFIC_SHOP_ON_DATE_FAIL"
export const CLEAR_ERRORS = "CLEAR_ERRORS"