export const ALL_COMPANY_REQUEST = "ALL_COMPANY_REQUEST"
export const ALL_COMPANY_SUCCESS = "ALL_COMPANY_SUCCESS"
export const ALL_COMPANY_FAIL = "ALL_COMPANY_FAIL"

export const ALL_COMPANY_POST_REQUEST = "ALL_COMPANY_POST_REQUEST"
export const ALL_COMPANY_POST_SUCCESS = "ALL_COMPANY_POST_SUCCESS"
export const ALL_COMPANY_POST_FAIL = "ALL_COMPANY_POST_FAIL"

export const ALL_COMPANY_UPDATE_REQUEST = "ALL_COMPANY_UPDATE_REQUEST"
export const ALL_COMPANY_UPDATE_SUCCESS = "ALL_COMPANY_UPDATE_SUCCESS"
export const ALL_COMPANY_UPDATE_FAIL = "ALL_COMPANY_UPDATE_FAIL"

export const ALL_COMPANY_DETAILS_REQUEST = "ALL_COMPANY_DETAILS_REQUEST"
export const ALL_COMPANY_DETAILS_SUCCESS = "ALL_COMPANY_DETAILS_SUCCESS"
export const ALL_COMPANY_DETAILS_FAIL = "ALL_COMPANY_DETAILS_FAIL"

export const ALL_COMPANY_DELETE_REQUEST = "ALL_COMPANY_DELETE_REQUEST"
export const ALL_COMOPANY_DELETE_SUCCESS = "ALL_COMPANY_DELETE_SUCCESS"
export const ALL_COMPANY_DELETE_FAIL = "ALL_COMPANY_DELETE_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"