export const ALL_STORAGE_REQUEST = "ALL_STORAGE_REQUEST"
export const ALL_STORAGE_SUCCESS = "ALL_STORAGE_SUCCESS"
export const ALL_STORAGE_FAIL = "ALL_STORAGE_FAIL"

export const ALL_STORAGE_POST_REQUEST = "ALL_STORAGE_POST_REQUEST"
export const ALL_STORAGE_POST_SUCCESS = "ALL_STORAGE_POST_SUCCESS"
export const ALL_STORAGE_POST_FAIL = "ALL_STORAGE_POST_FAIL"

export const ALL_STORAGE_UPDATE_REQUEST = "ALL_STORAGE_UPDATE_REQUEST"
export const ALL_STORAGE_UPDATE_SUCCESS = "ALL_STORAGE_UPDATE_SUCCESS"
export const ALL_STORAGE_UPDATE_FAIL = "ALL_STORAGE_UPDATE_FAIL"

export const ALL_STORAGE_DETAILS_REQUEST = "ALL_STORAGE_DETAILS_REQUEST"
export const ALL_STORAGE_DETAILS_SUCCESS = "ALL_STORAGE_DETAILS_SUCCESS"
export const ALL_STORAGE_DETAILS_FAIL = "ALL_STORAGE_DETAILS_FAIL"

export const ALL_STORAGE_DELETE_REQUEST = "ALL_STORAGE_DELETE_REQUEST"
export const ALL_STORAGE_DELETE_SUCCESS = "ALL_STORAGE_DELETE_SUCCESS"
export const ALL_STORAGE_DELETE_FAIL = "ALL_STORAGE_DELETE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"