export const GET_TEMP_SALE_REQUEST = "GET_TEMP_SALE_REQUEST"
export const GET_TEMP_SALE_SUCCESS = "GET_TEMP_SALE_SUCCESS"
export const GET_TEMP_SALE_FAIL = "GET_TEMP_SALE_FAIL"


export const TEMP_SALE_DETAILS_REQUEST = "TEMP_SALE_DETAILS_REQUEST"
export const TEMP_SALE_DETAILS_SUCCESS = "TEMP_SALE_DETAILS_SUCCESS"
export const TEMP_SALE_DETAILS_FAIL = "TEMP_SALE_DETAILS_FAIL"

export const TEMP_SALE_ON_SHOPNO_REQUEST = "TEMP_SALE_ON_SHOPNO_REQUEST"
export const TEMP_SALE_ON_SHOPNO_SUCCESS = "TEMP_SALE_ON_SHOPNO_SUCCESS"
export const TEMP_SALE_ON_SHOPNO_FAIL = "TEMP_SALE_ON_SHOPNO_FAIL"


export const POST_TEMP_SALE_REQUEST = "POST_TEMP_SALE_REQUEST"
export const POST_TEMP_SALE_SUCCESS = "POST_TEMP_SALE_SUCCESS"
export const POST_TEMP_SALE_FAIL = "POST_TEMP_SALE_FAIL"

export const UPDATE_TEMP_SALE_PRODUCTS_REQUEST = "UPDATE_TEMP_SALE_PRODUCTS_REQUEST"
export const UPDATE_TEMP_SALE_PRODUCTS_SUCCESS = "UPDATE_TEMP_SALE_PRODUCTS_SUCCESS"
export const UPDATE_TEMP_SALE_PRODUCTS_FAIL = "UPDATE_TEMP_SALE_PRODUCTS_FAIL"

export const UPDATE_TEMP_SALE_QUANTITY_IN_LIST_REQUEST = "UPDATE_TEMP_SALE_QUANTITY_IN_LIST_REQUEST"
export const UPDATE_TEMP_SALE_QUANTITY_IN_LIST_SUCCESS = "UPDATE_TEMP_SALE_QUANTITY_IN_LIST_SUCCESS"
export const UPDATE_TEMP_SALE_QUANTITY_IN_LIST_FAIL = "UPDATE_TEMP_SALE_QUANTITY_IN_LIST_FAIL"


export const TEMP_SALE_DELETE_REQUEST = "TEMP_SALE_DELETE_REQUEST"
export const TEMP_SALE_DELETE_SUCCESS = "TEMP_SALE_DELETE_SUCCESS"
export const TEMP_SALE_DELETE_FAIL = "TEMP_SALE_DELETE_FAIL"


export const TEMP_SALE_DELETE_ITEM_REQUEST = "TEMP_SALE_DELETE_ITEM_REQUEST"
export const TEMP_SALE_DELETE_ITEM_SUCCESS = "TEMP_SALE_DELETE_ITEM_SUCCESS"
export const TEMP_SALE_DELETE_ITEM_FAIL = "TEMP_SALE_DELETE_ITEM_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"